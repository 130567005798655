import React, { useEffect } from 'react';
import { Image, Linking, Platform, Text, TextInput, PermissionsAndroid, Alert, BackHandler, View , TouchableOpacity, StatusBar } from 'react-native';
import { Provider as PappyProvider } from 'react-native-paper';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import FlashMessage from "react-native-flash-message";
import { AppContext } from './AppContext';
import {} from "./components/common/web";
import useCachedResources from "./hooks/useCachedResources";
import Storage from './utils/StorageHelper2';
import ActivityIndicator from './components/common/ActivityIndicator';
import Popup from './components/common/MyPopup';
import SMSPopup from './components/common/SMSverify';
import AgePopup from './components/common/AgeVerify';
import PaymentPopup from './components/common/PaymentVerify';
import ProfileSettingApi from './services/api/ProfileSetting12Api';
import AuthLoadingScreen from './screens/AuthLoadingScreen';
import AuthStackNavigator from './navigation/AuthStackNavigator';
import NonAuthStackNavigator from './navigation/NonAuthStackNavigator';
import { nanoid } from 'nanoid/non-secure';
import { navigationRef } from './navigation/RootNavigation';
import Context from './components/common/Context';
import WebUtil from './utils/WebUtil';
import AppUtil from './utils/AppUtil';
import * as Linkings from 'expo-linking';
import CommonFunction from './utils/CommonFunction';
import LoginApi from './services/api/Login02Api';
import AuthHelper from './utils/AuthHelper';
import 'react-native-url-polyfill/auto';
import Overlay from './components/common/Overlay';
import Badge14Api from './services/api/Badge14Api';
import Msg from "./constants/option/Msg.json";
import KeyboardAccessory from './components/common/KeyboardAccessory';
import messaging from '@react-native-firebase/messaging';
import FireBaseCommon from './utils/FireBaseCommon';
import Notification from './utils/Notification';
import Toast, {DURATION} from 'react-native-easy-toast'
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import PushPopup from './components/common/PushPopup';
// import Toast, { InfoToast, ErrorToast } from 'react-native-toast-message';
import NotificationManager from 'react-native-check-notification-enable'
//import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import ExpoTrackingTransparencyWrapper from './utils/ExpoTrackingTransparencyWrapper';
import TransitionSpec from './constants/TransitionSpec';
import NavigationAuthStates from './constants/NavigationAuthStates';
import WebPushNotification from "./firebase/Notification";

const { requestTrackingPermissionsAsync } = ExpoTrackingTransparencyWrapper;

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors, 
    background: 'rgb(255, 255, 255)',
  }
};

// set api not check access time
ProfileSettingApi.setCheckAccessFlg(false);
LoginApi.setCheckAccessFlg(false);
Badge14Api.setCheckAccessFlg(false);

const prefix = Linkings.createURL('/');
const Stack = createStackNavigator();
const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';
const linking = {
  prefixes: [prefix, 'pappyapp://'],
  config: {
    screens: {
      NonAuth: {
        screens: {
          Index: '/Index',
          Register: '/Register/:path',
        }
      },
      Auth: '/',
      ChatHideSetting: '/HideBlockSetting/:memberId',
      Report: '/Report/:memberId',
      Profile: '/Profile/:memberId',
      SubscriptionComplete: '/subscription_complete.html',
      PointPurchaseComplete: '/point_purchase_complete.html',
      Home: {
        screens: {
          Search: { 
            screens: {
              SearchList: '/List',
              AdvanceSearch: '/Search',
              SameProfile: '/LikeProfile/:memberId',
            }
          },
          Meet: { 
            screens: {
              MeetList: '/Meet/List',
              MeetSearch: '/Meet/Search',
              MeetCalendar: '/Meet/Calendar',
            }
          },
          Iine: { 
            screens: {
              IineList: '/Good',
              SkipIineList: '/Good/Skip',
            }
          },
          Chat: { 
            screens: {
              ChatList: '/Chat',
              ChatRoom: '/Chat/Room/:memberId',
            }
          },
          MyPageStack: { 
            screens: {
              MyPage: '/MyPage',
              UserSetting: '/MyPage/SettingProfile',
              UserSettingImage: '/MyPage/SettingImage',
              Footmark: '/MyPage/Footmark:isPush',
              Notify: '/MyPage/Notify:isPush',
              SettingStack: {
                screens: {
                  Setting: '/MyPage/Setting'
                }
              }
            }
          }
        }
      }
    }
  }
};

const setRegisterState = async(state)=>{
  const key = state?.routes?.findIndex(item => 'NonAuth' === item.name);
  if(key==-1) return state;

  const subRoutes = state?.routes[key];
  const subKey = subRoutes?.state?.routes?.findIndex(item => 'Register' === item.name);
  if(subKey!=-1){
    if(state?.routes[key]?.state?.routes[subKey]?.params!=undefined){
      state.routes[key].state.routes[subKey].params.path = 'user-info';
    }
  }
  return state;
}

const setAuthState = async(state, componentName, params={})=>{
  const keyAuth = state?.routes?.findIndex(item => 'Auth' === item.name);
  if(keyAuth==-1) return state;
  
  let newStateComponent = [];
  let lastIndex = 0;
  if(state?.routes[keyAuth]?.state?.routes!=undefined){
    let stateComponent = state.routes[keyAuth]?.state?.routes;
    newStateComponent = stateComponent?.filter(function (item) {
      return (item.name != componentName)
    });
    lastIndex = newStateComponent?.length;
  }
  newStateComponent[lastIndex] = {
    name : componentName,
    key: `${componentName}-${nanoid()}`,
    params: params
  }
  state.routes[keyAuth].state.routes = newStateComponent;
  state.routes[keyAuth].state.index = lastIndex;
  CommonFunction.setAccessTime();

  return state;
}

const setNonAuthState = async(state, componentName, params={})=>{
  const keyNonAuth = state?.routes?.findIndex(item => 'NonAuth' === item.name);
  if(keyNonAuth==-1) return state;
  
  let newStateComponent = [];
  let lastIndex = 0;
  if(state?.routes[keyNonAuth]?.state?.routes!=undefined){
    let stateComponent = state.routes[keyNonAuth]?.state?.routes;
    newStateComponent = stateComponent?.filter(function (item) {
      return (item.name != componentName)
    });
    lastIndex = newStateComponent?.length;
  }
  newStateComponent[lastIndex] = {
    name : componentName,
    key: `${componentName}-${nanoid()}`,
    params: params
  }
  state.routes[keyNonAuth].state.routes = newStateComponent;
  state.routes[keyNonAuth].state.index = lastIndex;
  
  return state;
}

const setMyPageState = async(state, componentName, params)=>{
  const keyAuth = state?.routes?.findIndex(item => 'Auth' === item.name);
  if(keyAuth==-1) {
    if(CommonFunction.checkWebStage()){
      alert('keyAuth: fail');
    }
    return state;
  }

  let stateComponent = state?.routes[keyAuth]?.state?.routes;
  let newStateComponent = stateComponent?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.name === value.name
    ))
  );

  const keyHome = newStateComponent?.findIndex(item => 'Home' === item.name);
  if(keyHome==-1) {
    if(CommonFunction.checkWebStage()){
      alert('keyHome: fail');
    }
    return state;
  }

  const keyMypageStack = newStateComponent[keyHome]?.state?.routes?.findIndex(item => 'MyPageStack' === item.name);
  if(keyMypageStack==-1) {
    let length = newStateComponent[keyHome].state.routes.length;
    newStateComponent[keyHome].state.routes[length] = {
      name : 'MyPageStack',
      key: `MyPageStack-${nanoid()}`,
      type: 'stack'
    }
    keyMypageStack = length;
  };

  state.routes[keyAuth].state.index = keyHome;
  newStateComponent[keyHome].state.index = keyMypageStack;
  
  newStateComponent[keyHome].state.routes[keyMypageStack].state = {
    index: 0,
    routes:[
      {
      name : componentName,
      key: `${componentName}-${nanoid()}`,
      params: params
    }]
  }

  let length = newStateComponent?.length;
  if(length > (keyHome+1)){
    newStateComponent = newStateComponent?.filter(function (item) {
      return (item.name == 'Home')
    });
  }

  state.routes[keyAuth].state.routes = newStateComponent;
  CommonFunction.setAccessTime();
  
  await Storage.storeObjectData(PERSISTENCE_KEY, state);

  return state;
}

const createSettingStack = (screen)=>{
  const state = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          {name: 'Home', state: { 
            routes: [
              {name: 'MyPageStack', state: { 
                index: 1,
                routes: [
                  {name: 'MyPage'},
                  {name: 'SettingStack', state: { 
                    index: 1,
                    routes: [
                      {name: 'Setting'}, 
                      {name: screen}, 
                    ]}}, 
                ]}}, 
            ]}}, 
        ]}
      }
    ]
  };
  return state;
}

const createNonAuthStack = (screen, params={})=>{
  const state = {
    routes: [
      { name: 'NonAuth', state: { 
        index: 1, 
        routes: [
          {name: 'Index'}, 
          {name: screen, params: params}
        ]}
      }
    ]
  };
  return state;
}

const createIndexNonAuthStack = (params={})=>{
  const state = {
    routes: [
      { name: 'NonAuth', state: { 
        routes: [
          {name: 'Index', params: params}
        ]}
      }
    ]
  };
  return state;
}

const createIndexAuthStack = async(params={})=>{
  const state = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          {name: 'Home', params: params}
        ]}
      }
    ]
  };
  return state;
}

const getMemberIdParams = (initialUrl)=>{
  let memberId;
  const urlObj = new URL(initialUrl);
  if(urlObj?.pathname){
    const pathnames = urlObj.pathname?.split("/");
    if(Array.isArray(pathnames)){
      memberId = pathnames[pathnames.length-1];
    }
  }
  return memberId;
}

function RootStack() {
  return (
    <Stack.Navigator screenOptions={{
      gestureEnabled: CommonFunction.gestureEnabled(),
      animationEnabled: CommonFunction.animationEnabled(),
      transitionSpec: TransitionSpec
    }}>
      {/*<Stack.Screen name="Starter" component={AuthLoadingScreen} options={{headerShown: false}} />*/}
      <Stack.Screen name="NonAuth" component={NonAuthStackNavigator} options={{headerShown: false}} />
      <Stack.Screen name="Auth" component={AuthStackNavigator}  options={{headerShown: false}} />
    </Stack.Navigator>
  );
}

function WebRootStack(props) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="NonAuth" component={NonAuthStackNavigator} options={{headerShown: false}} />
      <Stack.Screen name="Auth" component={AuthStackNavigator}  options={{headerShown: false}} />
    </Stack.Navigator>
  );
}

function App() {
  const funcViewNavigait = async (data, rootFlg) => {
    // this.toast.close();
    Notification.funcViewNavigait(data, rootFlg);
  }

  if(Platform.OS!=='web'){
    useCachedResources();
    Text.defaultProps = Text.defaultProps || {};
    Text.defaultProps.allowFontScaling = false;
    TextInput.defaultProps = TextInput.defaultProps || {};
    TextInput.defaultProps.allowFontScaling = false;
  }
  
  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();
  const [loginFlag, setLoginFlag] = React.useState(false);
  const [restoreStateFlag, setRestoreStateFlag] = React.useState(false);
  // for AppContext
  const [appState, setAppState] = React.useState({});
  const stateContext = { appState, setAppState };
  const routeNameRef = React.useRef();
  const [fromikuyo, setFromikuyo] = React.useState(false);

  useEffect(() => {
    if(Platform.OS!=='web'){
      if(Platform.OS=== 'ios'){
        (async () => {
          const { status } = await requestTrackingPermissionsAsync();
          if (status === 'granted') {
          }
          if (status!=undefined) {
            // Firebase:プッシュポップアップ
            FireBaseCommon.requestFireBaseSetup();
          }
        })();
      }

      // 起動中のメッセージ受信
      messaging().onMessage(async remoteMessage => {
        if(Platform.OS=== 'android'){
          if(Platform.Version > 32){
            const isNotifySetting = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS);
            if(!isNotifySetting) return;
          }else{
            const isNotifySetting = await NotificationManager.areNotificationsEnabled();
            if(!isNotifySetting) return;
          }
        }
        CommonFunction.iconBadgeUpdata(0);
        // 画面上部に通知を表示
        if(remoteMessage) {
            this.pushPopupRef?.show(remoteMessage);
        // react-native-toast-messageのトースト
        // props = { remoteMessage:remoteMessage }
        // Toast.show({
        //   visibilityTime: 3000,
        //   autoHide: true,
        //   position: 'top',
        //   topOffset: 0,
        //   type: 'pushToast',
        //   text1: remoteMessage?.notification?.title,
        //   text2: remoteMessage?.notification?.body,
        //   props: props
        // })  
          // this.toast.show(
          //   <View style={{width:'98%'}}>
          //     <View style={{width:'100%'}}>
          //       <TouchableOpacity  onPress={()=>{
          //           funcViewNavigait(remoteMessage, 0);
          //         }} style={{flexDirection:'row', width:'100%'}}>
          //         <View style={{alignItems:'center',justifyContent:'center', width:'15%',height:'100%'}}>
          //           <Image style={{width:45, height:45, borderRadius:5}} source={require('./assets/images/icon_push.png')} />                    
          //         </View>
          //         <View style={{flexDirection: 'column',justifyContent:'center' ,width:'85%',height:'100%'}}>
          //           <Text style={{textAlign:'left', marginLeft:5}}>{remoteMessage.notification.body}</Text>
          //         </View>
          //       </TouchableOpacity>
          //       </View>
          //    </View>, DURATION.FOREVER, () => {
          // });
          // // プッシュ通知の自動消去
          // setTimeout(function(){
          //   this.toast.close();
          // }.bind(this), 3000);
        }
      });

      messaging().onNotificationOpenedApp(async remoteMessage => {
        if(Platform.OS === 'ios'){
          PushNotificationIOS.removeAllDeliveredNotifications();
          CommonFunction.iconBadgeUpdata(0);
        }
        if(remoteMessage) {
          // 共通の画面遷移処理
          funcViewNavigait(remoteMessage, 1);
        }
      });

      messaging().getInitialNotification().then(async remoteMessage => {
        if(remoteMessage) {
          // 未起動の時は特に処理無し（アイコンタップと同じ挙動）
        }
      });
    }
  }, []);
  const ref = React.useRef();

  React.useEffect(() => {    
    // WebUtil.AdjustScriptAddHeader();
    if(Platform.OS==='web'){
      // Adjust:webSDK
      WebUtil.AdjustScriptAddHeader();
    } else {
      // Adjust:react native Adjust
      AppUtil.AdjustMobilInitial();
      // iOS:のみ
      if(Platform.OS==='ios'){
        // iOS:バッジとプッシュ通知を消去
        PushNotificationIOS.removeAllDeliveredNotifications();
        CommonFunction.iconBadgeUpdata(0);
      }
    }

    const restoreState = async () => {
      try {
        if (Platform.OS === 'android') {
          if(Platform.Version > 32){
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS);
          }
        }
        let state = await Storage.getObjectData(PERSISTENCE_KEY);
        if (Platform.OS==='web') {
          let isLogin = false;
          let isRestoreState = false;
          const initialUrl = await Linking.getInitialURL();
          const checkUrl = initialUrl.toLowerCase();
          //const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
          //let state = savedStateString ? JSON.parse(savedStateString) : undefined;
          let newState;
          //if(state) isRestoreState = true;

          // login from ikuyo
          if(checkUrl.indexOf('fromikuyo')!=-1){
            setFromikuyo(true);
            const url = new URL(initialUrl);
            const ikuyoKey = url?.searchParams?.get('key');
            if(ikuyoKey!=undefined){
              AuthHelper.clearLoginData();

              if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
                Storage.removeItem('deviceToken_web');
              }

              const origin = url?.searchParams?.get('origin');
              if(origin!=undefined){
                Storage.storeData('origin', origin);
              }
              const response = await LoginApi.LoginByIkuyo(ikuyoKey);
              if(response?.status==1){
                 if(response?.response?.first==1){
                    Storage.storeData('isShowLoginPopup', 1);
                 } 

                isLogin = true;
                await ProfileSettingApi.ProfileGetCheck(stateContext, false);
              }
            }
          }else{
            let userProfile = {};
            const accessToken = await Storage.getData('accessToken');
            if(accessToken){
              userProfile = await ProfileSettingApi.ProfileGetCheck(stateContext, false);
            }

            const urlObj = new URL(initialUrl);
            const searchParams = Object?.fromEntries(urlObj?.searchParams?.entries());

            if(Object.keys(userProfile).length >0){
              isLogin = true;
              if(state){
                if(checkUrl.indexOf('list/regist')!=-1){
                  newState = NavigationAuthStates.ListRegist();
                }else if(checkUrl.indexOf('list/recommend')!=-1){
                  newState = NavigationAuthStates.ListRecommend();
                }else if(checkUrl.indexOf('mypage/footmark')!=-1){
                  newState = NavigationAuthStates.MyPageFootmark();
                }else if(checkUrl.indexOf('mypage/settingprofile')!=-1){
                  newState = NavigationAuthStates.MyPageSettingProfile();
                }else if(checkUrl.indexOf('footmark')!=-1){
                  newState = NavigationAuthStates.Footmark();
                }else if(checkUrl.indexOf('likeprofile')!=-1){
                  let memberId = getMemberIdParams(initialUrl);
                  if(memberId){ 
                    const params = { memberId: memberId }
                    newState = NavigationAuthStates.LikeProfile(params);
                  }else{
                    newState = await createIndexAuthStack();
                  }
                }else if(checkUrl.indexOf('profileimageinfo')!=-1){
                  newState = NavigationAuthStates.ProfileImageInfo();
                }else if(checkUrl.indexOf('profile')!=-1){
                  let memberId = getMemberIdParams(initialUrl);
                  if(memberId){ 
                    const params = { memberId: memberId }
                    newState = NavigationAuthStates.Profile(params);
                  }else{
                    newState = await createIndexAuthStack();
                  }
                }else if(checkUrl.indexOf('report')!=-1){
                  let memberId = getMemberIdParams(initialUrl);
                  if(memberId){ 
                    const params = { memberId: memberId }
                    newState = NavigationAuthStates.Report(params);
                  }else{
                    newState = await createIndexAuthStack();
                  }
                }else if(checkUrl.indexOf('hideblocksetting')!=-1){
                  let memberId = getMemberIdParams(initialUrl);
                  if(memberId){ 
                    const params = { memberId: memberId }
                    newState = NavigationAuthStates.ChatHideSetting(params);
                  }else{
                    newState = await createIndexAuthStack();
                  }
                }else if(checkUrl.indexOf('meet/list')!=-1){
                  newState = NavigationAuthStates.MeetList();
                }else if(checkUrl.indexOf('meet/calendar')!=-1){
                  newState = NavigationAuthStates.MeetCalendar();
                }else if(checkUrl.indexOf('good/other')!=-1){
                  newState = NavigationAuthStates.GoodOther();
                }else if(checkUrl.indexOf('good/my')!=-1){
                  newState = NavigationAuthStates.GoodMy();
                }else if(checkUrl.indexOf('good/skip')!=-1){
                  newState = NavigationAuthStates.GoodSkip();
                }else if(checkUrl.indexOf('chat/matching')!=-1){
                  newState = NavigationAuthStates.ChatMatching();
                }else if(checkUrl.indexOf('chat/conversation')!=-1){
                  newState = NavigationAuthStates.ChatConversation();
                }else if(checkUrl.indexOf('chat/room')!=-1){
                  let memberId = getMemberIdParams(initialUrl);
                  if(memberId){ 
                    const params = { memberId: memberId }
                    newState = NavigationAuthStates.ChatRoom(params);
                  }else{
                    newState = NavigationAuthStates.ChatMatching();
                  }
                }else if(checkUrl.indexOf('secretmode')!=-1){
                  newState = NavigationAuthStates.SecretMode();
                }else if(checkUrl.indexOf('previewuserinfo')!=-1){
                  newState = NavigationAuthStates.PreviewUserInfo();
                }else if(checkUrl.indexOf('memberstatus')!=-1){
                  newState = NavigationAuthStates.MemberStatus();
                }else if(checkUrl.indexOf('iineplan')!=-1){
                  newState = NavigationAuthStates.IinePlan();
                }else if(checkUrl.indexOf('pointshopping')!=-1){
                  newState = NavigationAuthStates.PointShopping();
                }else if(checkUrl.indexOf('pointlist')!=-1){
                  newState = NavigationAuthStates.PointList();
                }else if(checkUrl.indexOf('paidyInfo')!=-1){
                  newState = NavigationAuthStates.PaidyInfo();
                }else if(checkUrl.indexOf('purchase')!=-1){
                  if(checkUrl.indexOf('purchasetrial')!=-1){
                    newState = NavigationAuthStates.PurchaseTrial();
                  } else {
                    newState = NavigationAuthStates.Purchase();                  
                  }
                }else if(checkUrl.indexOf('smsinput')!=-1){
                  newState = NavigationAuthStates.SmsInput();
                }else if(checkUrl.indexOf('uploadidentification')!=-1){
                  newState = NavigationAuthStates.UploadIdentification();
                }else if(checkUrl.indexOf('identification')!=-1){
                  newState = NavigationAuthStates.Identification();
                }else if(checkUrl.indexOf('mypage/settingimage')!=-1){
                  newState = NavigationAuthStates.MyPageSettingImage();
                }else if(checkUrl.indexOf('mypage/setting')!=-1){
                  newState = NavigationAuthStates.MyPageSetting();
                }else if(checkUrl.indexOf('mypage')!=-1){
                  newState = NavigationAuthStates.MyPage();
                }else if(checkUrl.indexOf('mailinput')!=-1){
                  newState = NavigationAuthStates.MailInput();
                }else if(checkUrl.indexOf('mailvalidate')!=-1){
                  let params = {};
                  if(searchParams?.token && searchParams?.mail){
                    params = { token: searchParams?.token, mail: searchParams?.mail }
                  }
                  if(JSON.stringify(params)!='{}'){
                    newState = NavigationAuthStates.MailValidate(params);
                  }else{
                    newState = NavigationAuthStates.MailInput();
                  }
                }else if(checkUrl.indexOf('MailSMSValidate')!=-1){
                  let params = {};
                  if(searchParams?.token && searchParams?.mail){
                    params = { token: searchParams?.token, mail: searchParams?.mail }
                  }
                  if(JSON.stringify(params)!='{}'){
                    newState = NavigationAuthStates.MailSMSValidate(params);
                  }else{
                    newState = NavigationAuthStates.MailInput();
                  }
                }else if(checkUrl.indexOf('PassSMSValidate')!=-1){
                  let params = {};
                  if(searchParams?.token && searchParams?.mail){
                    params = { token: searchParams?.token, newPass: searchParams?.newPass }
                  }
                  if(JSON.stringify(params)!='{}'){
                    newState = NavigationAuthStates.PassSMSValidate(params);
                  }else{
                    newState = NavigationAuthStates.PassInput();
                  }
                }else if(checkUrl.indexOf('passinput')!=-1){
                  newState = NavigationAuthStates.PassInput();
                }else if(checkUrl.indexOf('femaleprivatemode')!=-1){
                  newState = NavigationAuthStates.FemalePrivateMode();
                }else if(checkUrl.indexOf('maleprivatemode')!=-1){
                  newState = NavigationAuthStates.MalePrivateMode();
                }else if(checkUrl.indexOf('privatemode')!=-1){
                  newState = NavigationAuthStates.PrivateMode();
                }else if(checkUrl.indexOf('hidelist')!=-1){
                  newState = NavigationAuthStates.HideList();
                }else if(checkUrl.indexOf('blocklist')!=-1){
                  newState = NavigationAuthStates.BlockList();
                }else if(checkUrl.indexOf('rule')!=-1){
                  newState = NavigationAuthStates.Rule();
                }else if(checkUrl.indexOf('terms')!=-1){
                  newState = NavigationAuthStates.Terms();
                }else if(checkUrl.indexOf('policy')!=-1){
                  newState = NavigationAuthStates.Policy();
                }else if(checkUrl.indexOf('law')!=-1){
                  newState = NavigationAuthStates.Law();
                }else if(checkUrl.indexOf('settlement')!=-1){
                  newState = NavigationAuthStates.Settlement();
                }else if(checkUrl.indexOf('notifinput')!=-1){
                  newState = NavigationAuthStates.NotifInput();
                }else if(checkUrl.indexOf('notify/all')!=-1){
                  newState = NavigationAuthStates.NotifyAll();
                }else if(checkUrl.indexOf('notify/individual')!=-1){
                  newState = NavigationAuthStates.NotifyIndividual();
                }else if(checkUrl.indexOf('notifydetail')!=-1){
                  let noticeId;
                  if(searchParams?.noticeId && searchParams?.type){
                    noticeId = searchParams.noticeId;
                  }
                  if(noticeId){
                    const params = {noticeId: noticeId, type: searchParams?.type}
                    newState = NavigationAuthStates.NotifyDetail(params);
                  }else{
                    newState = NavigationAuthStates.NotifyAll();
                  }
                }else if(checkUrl.indexOf('help')!=-1){
                  let params = {}
                  if(searchParams?.contact){
                    params = {contact: searchParams?.contact}
                  }
                  newState = NavigationAuthStates.Help(params);
                }else if(checkUrl.indexOf('withdraw?contact=1')!=-1 || checkUrl.indexOf('withdrawinput')!=-1 || checkUrl.indexOf('withdrawconfirm')!=-1){
                  newState = await setMyPageState(state, 'Withdraw',{contact:1});
                /*}else if(checkUrl.indexOf('help?contact=1')!=-1){
                  newState = await setAuthState(state, 'Help',{contact:1});
                }else if(checkUrl.indexOf('help')!=-1){
                  newState = await setAuthState(state, 'Help');
                */
                }else if(checkUrl.indexOf('subscription_complete.html')!=-1){ // for subscript complete
                  newState = await setMyPageState(state, 'SubscriptionComplete');
                }else if(checkUrl.indexOf('point_purchase_complete.html')!=-1){ // for point purchase complete
                  newState = await setMyPageState(state, 'PointPurchaseComplete');
                }else if(checkUrl.indexOf('notifinfo')!=-1){
                  newState = NavigationAuthStates.NotifInfo();
                }else{
                  newState = await createIndexAuthStack();
                }
              }
            }else{
              // not login
              if(checkUrl.indexOf('index?adjust_referrer')!=-1){
                const url = new URL(initialUrl);
                const params = Object?.fromEntries(url?.searchParams?.entries());
                newState = {
                  routes: [
                    { name: 'NonAuth', state: { 
                      routes: [
                        {name: 'Index', params: {adjust_referrer: params.adjust_referrer}}
                      ]}
                    }
                  ]
                };
              }else if(checkUrl.indexOf('login?static=1')!=-1){
                newState = createNonAuthStack('Login', {static: 1});
              }else if(checkUrl.indexOf('login')!=-1){
                newState = createNonAuthStack('Login');
              }else if(checkUrl.indexOf('DeviceVerification')!=-1){
                if(searchParams?.token && searchParams?.mail){
                  newState = {
                    routes: [
                      { name: 'NonAuth', state: { 
                        index: 1,
                        routes: [
                          {name: 'Index'},
                          {name: 'DeviceVerification', params: {sendAdd: searchParams?.sendAdd, sendPass: searchParams?.sendPass, token: searchParams?.token, sendAccept:  searchParams?.sendAccept}}
                        ]}
                      }
                    ]
                  };
                }else{
                  newState = createNonAuthStack('Login');
                }
              }else if(checkUrl.indexOf('register/user-info?static=1')!=-1){
                newState = createNonAuthStack('Register', {path: 'user-info', static: 1});
              }else if(checkUrl.indexOf('register')!=-1){
                newState = createNonAuthStack('Register', {path: 'user-info'});
              }else if(checkUrl.indexOf('activereminder')!=-1){
                if(searchParams?.token && searchParams?.mail){
                  newState = {
                    routes: [
                      { name: 'NonAuth', state: { 
                        index: 2,
                        routes: [
                          {name: 'Index'},
                          {name: 'Reminder'},
                          {name: 'ActiveReminder', params: {token: searchParams?.token, mail: searchParams?.mail}}
                        ]}
                      }
                    ]
                  };
                }else{
                  newState = createNonAuthStack('Reminder');
                }
              }else if(checkUrl.indexOf('reminder')!=-1){
                newState = createNonAuthStack('Reminder');
              }else if(checkUrl.indexOf('rule')!=-1){
                newState = createNonAuthStack('Rule');
              }else if(checkUrl.indexOf('terms')!=-1){
                newState = createNonAuthStack('Terms');
              }else if(checkUrl.indexOf('policy')!=-1){
                newState = createNonAuthStack('Policy');
              }else if(checkUrl.indexOf('law')!=-1){
                newState = createNonAuthStack('Law');
              }else if(checkUrl.indexOf('settlement')!=-1){
                newState = createNonAuthStack('Settlement');
              }else{
                newState = createIndexNonAuthStack();
              }
            }
          }
          
          if(newState) isRestoreState = true;

          setLoginFlag(isLogin);
          setRestoreStateFlag(isRestoreState);
          if(newState !== undefined && isRestoreState===true){
            setInitialState(newState);
          }
        }else{ // app
          // バージョン判定フラグ初期化
        await Storage.storeData('isShowVersionPopup', 0);
        
          let userProfile = {};
          const accessToken = await Storage.getData('accessToken');
          if(accessToken){
            userProfile = await ProfileSettingApi.ProfileGetCheck(stateContext, false);
          }
          
          if(Object.keys(userProfile).length >0){
            //let newState = await setAuthState(state, 'Home');
            let newState = await createIndexAuthStack();
            if(newState){
              setInitialState(newState);
            }
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    if(Platform.OS!=='web'){
      return <ActivityIndicator/>;
    }else{
      return <></>;
    }
  }
  return (
    <AppContext.Provider value={stateContext}>
      <PappyProvider>
        <StatusBar
          backgroundColor="#ffffff"
          barStyle={'dark-content'}
        />
        <NavigationContainer 
          theme={MyTheme}
          ref={navigationRef}
          linking={linking}
          documentTitle={{
            formatter: (options, route) => {
              const appName = process.env.APP_MANIFEST.name;
              if(route?.name=='Index' || route?.params?.screen=='Index') {
                return Msg.titlePage.Index;
              }else if(route?.name=='Law' || route?.params?.screen=='Law'){
                return Msg.titlePage.Law;
              }else if(route?.name=='Policy' || route?.params?.screen=='Policy'){
                return Msg.titlePage.Policy;
              }else if(route?.name=='Terms' || route?.params?.screen=='Terms'){
                return Msg.titlePage.Terms;
              }else if(route?.name=='Register' || route?.params?.screen=='Register'){
                return Msg.titlePage.Register;
              }else if((route?.name=='Login' || route?.params?.screen=='Login')){
                if(!options.tabBarLabel){
                  return Msg.titlePage.Login;
                }else{
                  return appName;
                }
              }else if(route?.name=='DeviceVerification' || route?.params?.screen=='DeviceVerification'){
                return Msg.titlePage.DeviceVerification;
              }else{
                const listName = ['Search','SearchList','Login','Regist','Meet','MeetList','Iine','Other','My','SkipIineList','Chat','Matching','Conversation','MyPageStack','MyPage','Recommend'];
                if(listName.indexOf(route?.name)!=-1) {
                  return appName;
                }

                let title = options?.title ?? route?.name;
                return (`${title} - ${appName}`)
              }
            }
          }}
          initialState={initialState}
          onReady={async() => {
            let currentRouteName = navigationRef?.getCurrentRoute()?.name;
            routeNameRef.current = currentRouteName;
            if(currentRouteName=='Index'){
              WebUtil.AdjustInitial();
            }else if(currentRouteName=='SearchList'){
              CommonFunction.setAccessTime();
            }
            if((loginFlag===true && fromikuyo===true)){
              navigationRef?.navigate('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
            }
            // reset api check access time
            ProfileSettingApi.resetCheckAccessFlg();
            LoginApi.resetCheckAccessFlg();
          }}
          onStateChange={async (state) =>{
            Storage.storeObjectData(PERSISTENCE_KEY, state);
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute().name;
            if (previousRouteName !== currentRouteName && previousRouteName!='Profile' && previousRouteName!='SubscriptionComplete' && previousRouteName!='PointPurchaseComplete' && previousRouteName!='Withdraw') {
              WebUtil.checkUpdateRefresh();
            }
            routeNameRef.current = currentRouteName;
          }}>
          {Platform.OS==='web'?
          <WebRootStack loginFlag={loginFlag} restoreStateFlag={restoreStateFlag}/>
          :
          <RootStack />
          }
          <FlashMessage position="center" />
          <Popup />
          <SMSPopup />
          <AgePopup />
          <PaymentPopup/>
          {/* {Platform.OS!=='web'?
            <Toast
              ref={(toast) => this.toast = toast}
              style={{backgroundColor:'#F6F6F6', width:'98%'}}
              position='top'
              positionValue={44}
              fadeInDuration={300}
              fadeOutDuration={300}
            />
          :null} */}
          <Context/>
          {Platform.OS==='web' && CommonFunction.isAndroidUA() ?
            <WebPushNotification />
          :null}
          {Platform.OS!=='web'?
            <PushPopup ref={ref=>{this.pushPopupRef=ref}} />
          :null} 
          {/* <Toast config={toastConfig} /> */}
          <Overlay/>
          <KeyboardAccessory/>
        </NavigationContainer>
      </PappyProvider>
    </AppContext.Provider>
  );
}
  
/*
  react-native-toast-messageのトーストのcustom定義
*/
const toastConfig = {
  pushToast: ({ text1, text2, props }) => (
    <View style={{ height: 'auto', width: '100%', backgroundColor: 'tomato', }}>
      <TouchableOpacity style={{paddingBottom:5, paddingTop:5 ,backgroundColor:'#f6f6f6',height:'auto',width:'100%',alignSelf:'center',flexDirection:'row',flex:1}} onPress={async ()=>{     
        Toast.hide();
        Notification.funcViewNavigait(props?.remoteMessage, 0);
      }}>
        <Image style={{margin:12,width:50,height:50,borderRadius:10}} source={require('./assets/images/push_icon.png')} />
        <View style={{ width: '70%',justifyContent: 'center',}}>
          <Text style={ margin=5}>{text2}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
};

export default App;
