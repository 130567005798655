import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { Formik } from 'formik';
import styles from '../../styles/FormStyles';
import { FormTextInputBorder } from '../../components/form/FormInput';
import CommonFunction from '../../utils/CommonFunction';
import Setting17Api from '../../services/api/Setting17Api';
import Msg from '../../constants/option/Msg.json';
import Validation from '../../constants/Validation';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import { AppContext } from '../../AppContext';
import ListStyles from '../../styles/ListStyles';
import ErrorCode from '../../constants/ErrorCode';
import PopupWrapper from '../../components/common/PopupWrapper';

export default class PassInputScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.initialValues = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      activated: 1,
      setAdd:'',
      pass: '',
      newPass:''
    }
    this.formik = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props.navigation;
      this.props?.setHeader('パスワードの変更', this.navigation);
      this.getDatatFromApi();
    }
  }

  async getDatatFromApi(){
    if(this._isMounted){
      try{
        const mailResponse = await Setting17Api.GetMail();
        //check response
        if(!CommonFunction.checkResponseStatus(mailResponse)) return;

        if(mailResponse!=undefined && mailResponse?.status==1){
          let data = mailResponse?.response;
          let activated = mailResponse?.activated;
          const count = (data?.mail.length);
          let setAdd = false;
          if (count!=0) {
            setAdd = true;
          }
          this.setState({
            setAdd: setAdd,
            activated: activated
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        setTimeout(() => {
          this.checkSmsMail();
        }, 50);
        this.setState({loading: false});
      }
    }
  }

  resetFormMessage = ()=>{
    this.formik?.setValues(this.initialValues);
  }

  async updateData(values){
    try{
      CommonFunction.overLay();
      let newPassStr = values['newPassword'];
      let passStr = values['currentPassword'];
      this.setState({
        newPass: newPassStr,
        pass: passStr
      });
      this.sendSmsPass?.showPopup();
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  // SMSアクティベートとメアド登録チェック
  checkSmsMail() {
    if (this.state.activated==0 || this.state.setAdd==false) {
      this.smsMailPopup?.showPopup();
    }
  }

  // SMSアクティベートとメアド登録チェック：ポップアップを閉じる
  onCloseSmsMailPopup() {
    this.smsMailPopup?.hidePopup();
  }
  
  // SMSアクティベートとメアド登録チェック：前の画面に戻る
  onCloseSmsMailPopupAction() {
    this.onCloseSmsMailPopup();
    this.navigation.goBack();
  }

  // 本人確認；SMS送信のポップアップを閉じる
  onCloseSendSmsPassAction() {
    this.sendSmsPass?.hidePopup();
  }

  // 本人確認；SMS送信アクション
  async onSendSmsPassAction() {
    this.sendSmsPass?.hidePopup();
    try{
      CommonFunction.overLay();
      let pass = this.state.pass;
      let newPass = this.state.newPass;
      const checkPassSmsResponse = await Setting17Api.PostSendSmsSetPassword( pass, newPass );

      // エラー
      const response = checkPassSmsResponse?.response;
      const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
      if(errorCode==ErrorCode.PassInput.NotMailAddress || errorCode==ErrorCode.PassInput.NotSms){
        PopupWrapper.showMessage('パスワードの設定、更新には電話番号認証とメールアドレスの登録が必要です。','warning');
        return;
      }else{
        if(!CommonFunction.checkResponseStatus(checkPassSmsResponse)) return;
      }
      if(checkPassSmsResponse!=undefined && checkPassSmsResponse?.status==1){
        const token = checkPassSmsResponse?.response?.token
        this.navigation.navigate('PassSMSValidate', { token: token, newPass: newPass});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay(0);
    }
  }

  render() {
    return (
      <View style={ListStyles.container}>
        <ScrollView style={styles.ScrollContainer}>
        <Formik
          innerRef={p => (this.formik = p)}
          initialValues={this.initialValues}
          validationSchema={Validation.SettingPassword}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.updateData(values) }
        >
          {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
              <View style={[styles.BodyContent, {padding:30}]}>
                <View style={styles.labelView}><Text style={styles.labelText}>現在のパスワード</Text></View>
                <FormTextInputBorder fieldName="currentPassword" secureTextEntry={true} placeholder="" autoComplete="off"/>
                <View style={[styles.labelView, {marginBottom:0,}]}>
                  <View style={{marginTop:15, marginBottom:5,}}>
                    {errors.newPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12}]}>※{errors.newPassword}</Text>) : null}
                    {errors.confirmPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12}]}>※{errors.confirmPassword}</Text>) : null}
                  </View>
                  <Text style={styles.labelText}>新しいパスワード</Text>
                  <Text style={{fontSize:12, lineHeight:18}}>※6～16文字の英数字で設定してください</Text>
                </View>
                <FormTextInputBorder fieldName="newPassword" secureTextEntry={true} showerror={false} autoComplete="off"/>

                <View style={[styles.labelView, {marginTop:30}]}><Text style={styles.labelText}>新しいパスワードの確認</Text></View>
                <FormTextInputBorder fieldName="confirmPassword" secureTextEntry={true} showerror={false} autoComplete="off"/>

                <View style={[styles.labelView, {marginTop:30}]}>
                  <Text style={{fontSize:12, fontWeight:'normal', lineHeight: 19, color:'#484848'}}>※必ず数字と英字の両方を使用してください。</Text>
                </View>

                <View style={[styles.buttonPanelSetting, {marginTop:40}]}>
                  <TouchableOpacity 
                    style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} 
                    onPress={handleSubmit} 
                    disabled={!(dirty)}
                  >
                    <Text style={styles.loginText}>変更する</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Fragment>
          )}          
        </Formik>
        <Popup ref={ref=>this.smsMailPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.onCloseSmsMailPopupAction();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
            <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>パスワードの設定、更新には{"\n"}電話番号認証とメールアドレスの{"\n"}登録が必要です。</Text>
            <TouchableOpacity onPress={()=>{this.onCloseSmsMailPopupAction()}} style={[myStyles.modalButtonWhite, {marginTop:20}]}>
              <Text style={myStyles.textModalButtonWhite}>閉じる</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        <Popup ref={ref=>this.sendSmsPass=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.onCloseSendSmsPassAction();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
            <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>パスワードの登録/変更{"\n"}には電話番号での{"\n"}本人確認が必要です。</Text>
            <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>{"\n"}送信ボタンを押して{"\n"}本人確認をお願いします。{"\n"}{"\n"}</Text>
            <TouchableOpacity onPress={()=>{this.onSendSmsPassAction()}} style={[myStyles.submitBtn, {marginTop:20}]}>
              <Text style={myStyles.txtBtnWh}>送信</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        </ScrollView>
      </View>
    );
  }
}

const myStyles = StyleSheet.create({
  submitBtn:{
    width:"80%",
    margin: 0,
    backgroundColor:"#5cc8ca",
    borderRadius:25,
    height:46,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnWh:{
    color:"white",
    fontWeight:"bold",
    fontSize: 16,
    textAlign:'center'
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
});