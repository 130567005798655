import React from 'react';
import { View, Image, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import NoItemsBox from '../../components/common/NoItemsBox';
import ChatUserRowFormat from '../../components/data/ChatUserRowFormat';
import styles from '../../styles/CommonStyles';
import LoadMore from '../../components/screens/LoadMore';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { RefreshControl } from 'react-native-web-refresh-control';
import Constants from '../../constants/Constants';
import MailBoxApi from "../../services/api/MailBox05Api";
import UserUtil from "../../utils/UserUtil";
import { SwipeListView } from 'react-native-swipe-list-view';
import HideApi from '../../services/api/Hide08Api';
import CommonFunction  from '../../utils/CommonFunction';
import UserVerify from '../../utils/UserVerify';
import Badge14Api from '../../services/api/Badge14Api';
import HeaderOptions from '../../constants/HeaderOptions';
import FavoriteSetting from '../../components/common/FavoriteSetting';
import StorageHelper from '../../utils/StorageHelper2';
import ChatReportPopup from '../../components/common/ChatReportPopup';
import FavoriteNameSetting from '../../components/common/FavoriteNameSetting';

const {checkUndefined} = CommonFunction;
const bannerHeight = 80;

export default class ChatList extends LoadMore {
  constructor() {
    super();
    this.FavoriteSettingRef = React.createRef();
    this.FavoriteNameSettingRef = React.createRef();
    this.state = {
      ...this.state,
      maskUp: false,
      badge: {},
      scrollHeight: 0,
      trialPlan: false,
    }
    this.keyFavoriteSearch = 'FavoriteSearch';
    this.ChatReportPopupRef = React.createRef();
    this.messageBoxId = null;
    this.rowData = [];
    this.namesId = { single: 1, double: 2, triple: 3 };
    this.favoriteNames = {
      single: '',
      double: '',
      triple: '',
   };
  }
  _currentMemberId = '';
  componentDidMount() {
    super.componentDidMount();
    if (this._isMounted) {
      // from back page, if use await in componentDidMount, focus listener not fire when first mount
      this.subscribeFocus = this.props.navigation?.addListener('focus', async() => {
        CommonFunction.checkAccessTime();    
        await this.checkRemoveSearchList();
        this.checkItemDataUpdata();
      });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.appContext;
    if(appState.setChatHideMemberId){
      this.setState({maskUp: true}, ()=>{
        const data = this.state.data;
        const removeData = data?.filter(function (item) {
          return item.memberId == appState.setChatHideMemberId;
        });
        if(removeData!=undefined && Array.isArray(removeData)){
          let unreadCount = removeData[0]?.unreadCount;
          this.decreaseBadge(unreadCount)
        }

        const filterData = data?.filter(function (item) {
          return item.memberId != appState.setChatHideMemberId;
        });
        appState.setChatHideMemberId = null;
        setAppState(appState);
        this.setState({data: filterData, maskUp: false});
      });
    }
  }
  
  decreaseBadge = (unreadCount=0)=>{
    let { badge } = this.state;
    unreadCount = parseInt(unreadCount);
    let unreadMatchCount = parseInt(badge.unreadMatchCount);
    let unreadReplyCount = parseInt(badge.unreadReplyCount);
    let message = parseInt(badge.message);
    if(isNaN(unreadCount)) unreadCount = 0;
    if(this.type==0){
      if(!isNaN(unreadMatchCount) && unreadMatchCount > 0)
        unreadMatchCount -= unreadCount;
    }else{
      if(!isNaN(unreadReplyCount) && unreadReplyCount > 0)
      unreadReplyCount -= unreadCount;
    }

    if(!isNaN(message) && message > 0) {
      message -= unreadCount;
    }

    badge.unreadMatchCount = unreadMatchCount;
    badge.unreadReplyCount = unreadReplyCount;
    badge.message = message;
    this.setState({ badge: badge});
    if(this.props.setBadge!=undefined) {
      this.props?.setBadge({
        good: badge.good,
        notification: badge.notification,
        footmark: badge.footmark,
        message: badge.message,
        contact: badge.contact,
      });
    }
    if(this.props.setTopBadge!=undefined) {
      this.props?.setTopBadge({
        unreadMatchCount: badge.unreadMatchCount,
        unreadReplyCount: badge.unreadReplyCount
      });
    }
  }

  getChatList = async(values)=>{
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }
      let favorite = await StorageHelper.getData(this.keyFavoriteSearch);
      if(favorite==undefined) {
        favorite = 0;
        StorageHelper.storeObjectData(this.keyFavoriteSearch, favorite);
      }
      let noReply = 0;
      if (favorite==4) {
        noReply = 1;
        favorite = 0;
      }
      try{
        let badge = {};
        const params = {page: values.page, type: values.type, favorite: favorite, noReply:noReply};
        const mailBoxListResponse = await MailBoxApi.MailBoxList(params);
        if(JSON.stringify(mailBoxListResponse)=='{}'){
          this.setState({maskUp: true});
        }
        //check response
        if(!CommonFunction.checkResponseStatus(mailBoxListResponse)) return;

        if(mailBoxListResponse?.status==1){
          if(values.page==1){
            // get badge
            const badgeResponse = await Badge14Api.GetBadge();
            badge = badgeResponse?.response;
            if(badge!=undefined){
              badge.unreadMatchCount = 0;
              badge.unreadReplyCount = 0;
            }
          }
          
          const mailBoxList = mailBoxListResponse.response; 
          let account = UserUtil.getAccountAppState(mailBoxListResponse, this.appContext);
          const userProfile = await UserUtil.getUserProfileLocal(this.appContext.appState);
          const { commonVar } = this.state;
          
          // MailBoxListからデータ取得
          let trialPlan = mailBoxList.trialPlan;
          StorageHelper.storeData('trialPlan', trialPlan);          
          commonVar.paymentFlag = (checkUndefined(mailBoxListResponse.subscription))?mailBoxListResponse.subscription?.status:0;
          commonVar.sex = (checkUndefined(userProfile))?userProfile.sex:null;
          this.setState({
            account: account, 
            commonVar: commonVar,
            trialPlan: trialPlan
          },()=>{
            this.setBanner();
            let otherTabRef = this.props?.otherTabRef();
            if(otherTabRef){
              if(typeof otherTabRef?.setState=='function') otherTabRef?.setState({account: account});
            }
          });

          if(checkUndefined(mailBoxList)){
            const mailbox = mailBoxList.mailbox;
            // set tab badge
            if(badge!=undefined){
              badge.unreadMatchCount = mailbox?.unreadMatchCount;
              badge.unreadReplyCount = mailbox?.unreadReplyCount;
            }

            let longRefreshControl = false
            if(checkUndefined(mailbox)){
              if(mailbox?.count > 0){
                let list = mailbox.list;
                CommonFunction.thumbnailUrlConver(list);
                if(values.page > 1){
                  this.setState({ data: this.state.data.concat(list), type: this.type });
                }else{
                  this.setState({ data: list, type: this.type });
                }
              }else{
                longRefreshControl = true;
              }
              this.no_more = (mailbox.nextVal == 0)?1:0
            }
            this.setState({ longRefreshControl: longRefreshControl });
          }

          if(values.page==1){
            this.setState({badge: badge});
            if(checkUndefined(this.props.setBadge)){
              this.props.setBadge(badge);
            }
            if(checkUndefined(this.props.setTopBadge)){
              this.props.setTopBadge(badge);
            }
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account.ageVerficationStatus,
      navigation: this.props.navigation,
    });
  }

  checkSMS = ()=>{
    if(!this._isMounted) return;

    if(UserVerify.checkSMSAppState(this.appContext)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account.activated, 
      navigation: this.props.navigation,
      account: this.state.account
    });
  }

  setBanner = ()=>{
    if (!this._isMounted) return;

    const { commonVar } = this.state;
    commonVar.showAgeBanner = false;
    commonVar.showPaymentBanner = false;
    //check SMS
    if(this.state.account?.activated==0){
      return;
    }

    // check Age
    if(this.state.account?.ageVerficationStatus!=2){
      commonVar.showAgeBanner = true;
      this.setState({commonVar:commonVar});
      if(this.props.setTabBottomWidth!=undefined){
        this.props.setTabBottomWidth(0);
      }
      return;
    }

    // check Payment
    if(this.state.commonVar?.sex==Constants.MALE && this.state.commonVar?.paymentFlag==0){
      commonVar.showPaymentBanner = true;
      this.setState({commonVar:commonVar});
      if(this.props.setTabBottomWidth!=undefined){
        this.props.setTabBottomWidth(0);
      }
      return;
    }
  }

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;

    return (
      <NoItemsBox msg='メッセージはありません。' />
    );
  }

  setParentState = ()=>{
    // other tab reference
    let otherTabRef = this.props?.otherTabRef();
    if(otherTabRef){
      let otherTabState = otherTabRef?.state;
      if(otherTabState!=undefined){
        if(otherTabRef.page!=undefined){
          otherTabState.page = otherTabRef.page;
        }
        if(otherTabRef.no_more!=undefined){
          otherTabState.no_more = otherTabRef.no_more;
        }
        otherTabRef?.props?.parentState(otherTabState);
      }
    }
  }

  handlerClick = (item) => {
    // if(this.type!=0 && !this.checkSMS()) return false;
    
    const memberId = item.memberId;
    this._currentMemberId = memberId;

    if(item.leaveFlag != 0){
      this.ChatReportPopupRef?.show();
    }else{
      if(memberId!=undefined){
        let { data } = this.state;
        const findIndex = data?.findIndex(
          items => memberId == items.memberId
        );
    
        if(findIndex!=-1){
          this.decreaseBadge(item.unreadCount);
          data[findIndex].unreadCount = '';
        }
        this.setState({data: data});
      }
      this.setParentState();
      this.props.navigation.navigate('ChatRoom', { memberId:item.memberId });
    }
  }

  handlerHide = async(rowData, rowMap)=>{
    if(!this.checkSMS()) return false;
    if(!this.checkAge()) return false;

    const { item } = rowData;
    const memberId = item?.memberId;
    const rowIndex = memberId+rowData?.index;

    if(checkUndefined(memberId)){
      try{
        CommonFunction.overLay();
        const response = await HideApi.PostHide(memberId);
        const success = response?.status;
        if(success==1){
          if(rowIndex!=undefined){
            rowMap[rowIndex]?.closeRow();
          }

          let { data } = this.state; 
          if(data.length > 0){
            data = data.filter(item => item.memberId !== memberId);
            this.setState({data: data});
          }
          this.decreaseBadge(item.unreadCount)
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        CommonFunction.hideOverlay();
      }
    }
  }

  handlerFavorite = async(rowData, rowMap)=>{
    const { item } = rowData;
    const messageBoxId = item?.messageBoxId;
    const favorite = item?.favorite;
    const rowIndex = item?.memberId+rowData?.index;
    if(rowIndex!=undefined){
      rowMap[rowIndex]?.closeRow();
    }
    this.messageBoxId = messageBoxId;
    this.rowData = rowData;
    const favoriteNameListResponse = await MailBoxApi.FavoriteNameList();
    if(!CommonFunction.checkResponseStatus(favoriteNameListResponse)) return;    
    if(favoriteNameListResponse?.status==1){
      const favoriteItems = favoriteNameListResponse?.response?.favorite.list;
      this.favoriteNames.single = favoriteItems[0].name;
      this.favoriteNames.double = favoriteItems[1].name;
      this.favoriteNames.triple = favoriteItems[2].name;
      const params = { targetMessageBoxId: messageBoxId, targetFavorite: favorite, targetRowData: rowData, favoriteNames: this.favoriteNames};
      this.FavoriteSettingRef?.show(params);
    }
  }

  isSectionsEmpty(){
    if(this.state.data?.length >0) return false;
    else return true;
  }

  getItemLayout = (data, index) => {
    const length = 81; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  setViewIndex = (index)=>{
    let {appState, setAppState} = this.appContext;
    appState.chatViewIndex = index;
    setAppState(appState);
  }

  onRightAction=(rowKey, rowMap)=>{
    // validate Age
    const validateAge = this.checkAge();
    if(!validateAge){
      rowMap[rowKey]?.closeRow();
    }
  }
  
  checkItemDataUpdata = async()=>{
    let { appState, setAppState} = this.appContext;
    if(appState.setFavoritUpdate){
      let item = appState.setFavoritUpdate;
      this.favoriteChatListUpdata(item?.targetMemberId, item?.targetFavorite);
      appState.setFavoritUpdate = null;
      setAppState(appState);
    }
  }

  chatListItemUpdata = async(params)=>{
    // リストのスワイプメニューから直接変更（お気に入り検索の条件と違っていたら削除、条件なしの時だけ画面更新）
    let favorite = await StorageHelper.getData(this.keyFavoriteSearch);
    let targetFavorite = params.targetFavorite;
    let targetRowData = params.targetRowData;
    const { item } = targetRowData;
    const memberId = item?.memberId;
    if (Number(favorite)==0) {
      this.favoriteChatListUpdata(memberId, targetFavorite);
    } else {
      if (targetFavorite!=Number(favorite)){
        this.checkHideFavoriteData(targetRowData);
      }
    }
  }

  favoriteChatListUpdata = (setMemberId, setfavorite) => {
    if(checkUndefined(setMemberId)){
      try{
        let { data } = this.state;
        if(data.length > 0){
          data = data.map((item) => (item?.memberId == setMemberId ? Object.assign(item, { favorite: setfavorite }) : item));
          this.setState({data: data});
        }
        //this.decreaseBadge(item.unreadCount)
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
      }
    }
  }

  checkHideFavoriteData = async(rowData)=>{
    const { item } = rowData;
    const memberId = item?.memberId;
    const rowIndex = rowData.index;
    if(checkUndefined(memberId)){
      try{
        let { data } = this.state; 
        if(data.length > 0){
          data = data.filter(item => item.memberId !== memberId);
          this.setState({data: data});
        }
        this.decreaseBadge(item.unreadCount)
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
      }
    }
  }

  onReportAction= ()=>{
    if(!this.checkSMS()) { 
      this.ChatReportPopupRef?.hide();
      return false;
    }
    if(!this.checkAge()) { 
      this.ChatReportPopupRef?.hide();
      return false;
    }
    this.ChatReportPopupRef?.hide();
    this.props.navigation?.navigate('Report', {memberId: this._currentMemberId});
  }

  onBrockAction= ()=>{
    if(!this.checkSMS()) { 
      this.ChatReportPopupRef?.hide();
      return false;
    }
    if(!this.checkAge()) { 
      this.ChatReportPopupRef?.hide();
      return false;
    }
    this.ChatReportPopupRef?.hide();
    this.props.navigation?.navigate('ChatHideSetting', {memberId: this._currentMemberId});
  }

  favoriteNameSetting = (getParams)=>{
    const params = {selected: getParams.selected, slectNameChange: getParams.slectNameChange, favoriteNames: this.favoriteNames};
    this.FavoriteNameSettingRef?.show(params);
  }

  favoriteNameUpdata = (getParams)=>{
    if (getParams.slectNameChange!=undefined) {
      if (getParams.slectNameChange == this.namesId.single) {
        this.favoriteNames.single = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.double) {
        this.favoriteNames.double = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.triple) {
        this.favoriteNames.triple = getParams.updataFavoriteName;
      }
    }
    const params = {targetFavorite: getParams.selected, favoriteNames: this.favoriteNames, targetMessageBoxId: this.messageBoxId, targetRowData: this.rowData};
    this.FavoriteSettingRef?.show(params);

    this.messageBoxId = null;
    this.rowData = [];
  }

  render() {
    let showLoading = false;
    if(this.state.loading && this.page === 1) showLoading = true;
    if(this.state.maskUp===true) showLoading = true;

    if (showLoading===true) return <ActivityIndicator />;

    let DATA = [{title:'', data: this.state.data}];
    let length = this.state.data?.length;
    if(length<15) length = 15;

    return (
      <View style={[styles.container, {paddingBottom:Platform.OS!='web'?0:HeaderOptions.bottomTabHeight-1}]} onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        if(this.state.scrollHeight==0 && height>0){
          let scrollHeight = height - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomTabHeight;
          if(this.state.commonVar?.showAgeBanner || this.state.commonVar?.showPaymentBanner){
            scrollHeight = scrollHeight - bannerHeight;
          }
          this.setState({scrollHeight: scrollHeight});
        }
      }}>
        <FavoriteSetting ref={ref=>this.FavoriteSettingRef=ref} favoriteUpdata={this.chatListItemUpdata} nameSetting={this.favoriteNameSetting} />
        <FavoriteNameSetting ref={ref=>this.FavoriteNameSettingRef=ref} filterUpdata={this.favoriteNameUpdata} />
        <ChatReportPopup navigation={this.props.navigation} onReportAction={this.onReportAction} onBrockAction={this.onBrockAction} ref={ref=>this.ChatReportPopupRef=ref} /> 
        {this.state.commonVar?.showAgeBanner && (
          <TouchableOpacity style={{width:'100%',height:bannerHeight}} onPress={()=>{
            if(!this.checkSMS()) return false;

            this.props.navigation?.navigate('Auth',{screen:'Identification'});
          }}>
            {this.state.commonVar.sex==Constants.MALE?
            <Image style={{width:'100%',height:bannerHeight}} source={require('../../assets/images/banner-age-male.png')} resizeMode="stretch" />
            :
            <Image style={{width:'100%',height:bannerHeight}} source={require('../../assets/images/banner-age-female.png')} resizeMode="stretch" />
            }
          </TouchableOpacity>
        )}
        {this.state.commonVar?.showPaymentBanner && (
        <TouchableOpacity style={{}} onPress={()=>{
          if(!this.checkSMS()) return false;
          if(this.state.trialPlan==true) {
            this.props.navigation?.navigate('PurchaseTrial');
          } else {
            this.props.navigation?.navigate('Purchase');
          }
         }}>
          {this.state.trialPlan==true?
            <Image style={{width:'100%',height:80}} source={require('../../assets/images/trial/banner_trial_msg.png')} resizeMode="stretch" />
          :
            <View>
              {Platform.OS==='web'?
              <Image style={{width:'100%',height:80}} source={require('../../assets/images/banner-payment-web.png')} resizeMode="stretch" />
              :
              <Image style={{width:'100%',height:80}} source={require('../../assets/images/banner-payment.png')} resizeMode="stretch" />
              }
            </View>
          }
        </TouchableOpacity>
        )}
        <SwipeListView 
          contentContainerStyle={
            Platform.OS!=='web'?{ 
              flexGrow:1 
            }:{}
          }
          style={
            Platform.OS==='web'?{ 
              height: this.state.scrollHeight
            }:{}
          }
          listViewRef={(ref)=>this.scrollRef=ref}
          useSectionList={true}
          refreshControl={
            <RefreshControl
              enabled={this.state.refreshControl}
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          sections={this.isSectionsEmpty() ? [] : DATA}
          ListEmptyComponent={this.noItemDisplay}
          renderItem={({item,index}) => (
            <TouchableOpacity activeOpacity={1} onPress={()=>{this.handlerClick(item)}}>
              <ChatUserRowFormat index={index} setViewIndex={this.setViewIndex} item={item} navigation={this.props.navigation} setParentState={this.setParentState} checkSMS={this.checkSMS} />
            </TouchableOpacity>
          )}
          renderHiddenItem={(data,rowMap) => {
            return (
              <View style={innerStyles.hideContainer}>
                <TouchableOpacity style={innerStyles.hideContainerL} onPress={()=>{ 
                  this.handlerFavorite(data, rowMap);
                  }}>
                  <View style={innerStyles.hideWrapperFavorite}><Text style={innerStyles.txtHide}>お気に入り</Text></View>
                </TouchableOpacity>
                <TouchableOpacity style={innerStyles.hideContainerR} onPress={()=>{ 
                  this.handlerHide(data, rowMap);
                  }}>
                  <View style={innerStyles.hideWrapper}><Text style={innerStyles.txtHide}>非表示</Text></View>
                </TouchableOpacity>
              </View>
            );
          }}
          extraData={this.state.data}
          //onRowOpen={this.onRightAction}
          //onRightAction={this.onRightAction}
          rightActivationValue={-150}
          rightActionValue={-150}
          disableRightSwipe={true}
          closeOnRowPress={true}
          rightOpenValue={-150}
          keyExtractor={(item,index) => item?.memberId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={0.5}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          initialNumToRender={length}
        />
      </View>
    );
  }
}

const innerStyles = StyleSheet.create({
  hideContainer:{
    flexDirection: 'row',
    alignSelf:'flex-end',
    width:150, 
    height:'100%',
    ...Platform.select({
      web:{
        justifyContent:'right',
        alignItems:'right',
      }
    })
  },
  hideContainerR:{
    flexDirection: 'row',
    alignSelf:'flex-end',
    width:75, 
    height:'100%',
    ...Platform.select({
      web:{
        justifyContent:'right',
        alignItems:'right',
      }
    })
  },
  hideContainerL:{
    flexDirection: 'row',
    alignSelf:'flex-start',
    width:75, 
    height:'100%',
    ...Platform.select({
      web:{
        justifyContent:'left',
        alignItems:'left',
      }
    })
  },
  hideWrapper:{
    backgroundColor:'#484848',
    width:'98%',
    height:'98%',
    justifyContent:'center',
    alignContent:'center',
  },
  hideWrapperFavorite:{
    backgroundColor:'#78B9D3',
    width:'98%',
    height:'98%',
    justifyContent:'center',
    alignContent:'center',
  },
  txtHide:{
    color:'#ffffff', 
    textAlign:'center',
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  }
});