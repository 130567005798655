import { Platform } from 'react-native';
import Storage from '../utils/StorageHelper2';
import AppConfig from '../constants/AppConfig';

let currentElem=null;
let safeAreaInsetTop=280;

var stopScroll = function() {
    if(Platform.OS==='web'){
        if(currentElem){
            var rect = currentElem.getBoundingClientRect();
            var offset = { 
                top: rect.top + window.scrollY-safeAreaInsetTop, 
                left: rect.left + window.scrollX, 
            };
            window.scrollTo({top: offset.top, behavior: 'smooth'});   
        }       
    }
}

var disableScrolling = function() {
    if(Platform.OS==='web'){
        currentElem = document.querySelector('input:focus');
        if(currentElem==null){
            currentElem = document.querySelector('textarea:focus');
        }
        if(currentElem){
            document.addEventListener("touchend", stopScroll);
            setTimeout(function(){
              stopScroll();
            },10);    
        }
    }
}

var enableScrolling = function() {
    if(Platform.OS==='web'){
        currentElem = undefined;
        document.removeEventListener("touchend", stopScroll);
    }
}

var resizeImage = function (settings) {
    if(Platform.OS==='web'){
        var file = settings.file;
        // var maxSize = settings.maxSize;
        var maxWidth = settings.maxWidth;
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            var width = image.width;
            var height = image.height;
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
            // if (width > height) {
            //     if (width > maxSize) {
            //         height *= maxSize / width;
            //         width = maxSize;
            //     }
            // } else {
            //     if (height > maxSize) {
            //         width *= maxSize / height;
            //         height = maxSize;
            //     }
            // }
            canvas.width = width;
            canvas.height = height;
            let context = canvas.getContext('2d', { colorSpace: "srgb" });
            context.drawImage(image, 0, 0, width, height);        
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        
        return new Promise(function (ok, no) {
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    }    
};

var checkUpdateRefresh = async function() {
    if(Platform.OS==='web' && __DEV__===false ){
        const t = new Date().getTime();
        const url_check_update = '/check_update.php?t='+t;
        const currentUpdate = await Storage.getData('currentUpdate');
        let lastUpdate=0;
        await fetch(url_check_update).then((response) => response.json()).then((data) => lastUpdate=data);
        if(currentUpdate != lastUpdate){
            await Storage.storeData('currentUpdate',lastUpdate);
            location.reload();
        }
    }    
}

var AdjustScriptAddHeader = function(){
    if(Platform.OS==='web'){
        const head = document.querySelector("head");
        if(head.innerHTML.indexOf('adjust-latest.min.js')<0){
            const script = document.createElement("script");
            script.innerHTML = '!function(t,a,e,r,s,l,d,n,o){t.Adjust=t.Adjust||{},t.Adjust_q=t.Adjust_q||[];for(var c=0;c<l.length;c++)d(t.Adjust,t.Adjust_q,l[c]);n=a.createElement("script"),o=a.getElementsByTagName("script")[0],n.async=!0,n.src="https://cdn.adjust.com/adjust-latest.min.js",n.onload=function(){for(var a=0;a<t.Adjust_q.length;a++)t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust,t.Adjust_q[a][1]);t.Adjust_q=[]},o.parentNode.insertBefore(n,o)}(window,document,0,0,0,["initSdk","trackEvent","addGlobalCallbackParameters","addGlobalPartnerParameters","removeGlobalCallbackParameter","removeGlobalPartnerParameter","clearGlobalCallbackParameters","clearGlobalPartnerParameters","switchToOfflineMode","switchBackToOnlineMode","stop","restart","gdprForgetMe","disableThirdPartySharing"],function(t,a,e){t[e]=function(){a.push([e,arguments])}});';
            head.appendChild(script);  
        }
    }
}
var AdjustInitial = async function(){
    if(Platform.OS==='web'){
        if(__DEV__){
          console.log('initSdk',AppConfig);
        }
        Adjust.initSdk({ appToken: AppConfig.Addjust.appToken, environment: AppConfig.Addjust.environment, logLevel: "none" });
    }
}

var getWebUUID = async function(){
  if(Platform.OS==='web'){
    let webUUID = '';
    
    if(Adjust !=undefined){
      if(typeof Adjust.getWebUUID == 'undefined') {
        AdjustInitial();
      }else{
        webUUID = Adjust?.getWebUUID();
        
        if(!webUUID){
          AdjustInitial();
        }
      }

      if(typeof Adjust.getWebUUID == 'function' && !webUUID) {
        await sleep(1000);
        webUUID = Adjust?.getWebUUID();
      }
    }
    return webUUID;
  }
}

var sleep = async (milliseconds) => {
  await new Promise(resolve => {
    return setTimeout(resolve, milliseconds)
  });
};

export default {     
    enableScrolling, 
    disableScrolling, 
    stopScroll,
    resizeImage,
    checkUpdateRefresh,
    AdjustScriptAddHeader,
    AdjustInitial,
    getWebUUID
}