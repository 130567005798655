import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { Formik } from 'formik'
import Validation from '../../constants/Validation';
import styles from '../../styles/FormStyles';
import { FormTextInputBorder } from '../../components/form/FormInput';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import CommonFunction from '../../utils/CommonFunction';
import Setting17Api from '../../services/api/Setting17Api';
import { AppContext } from '../../AppContext';
import ListStyles from '../../styles/ListStyles';
import PopupWrapper from '../../components/common/PopupWrapper';
import ErrorCode from '../../constants/ErrorCode';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';

export default class MailInputScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      mail : '',
      inputCurrentMail : '',
      current_mail: '',
      mailError: '',
      loading: true,
      notSetAdd: false,
      activated: 1,
      params: '',
      mailText:'',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.props?.setHeader('メールアドレスの変更',this.navigation);
      this.getDatatFromApi();

      this.subscribeFocus = this.props.navigation?.addListener('focus', async() => {
        setTimeout(() => {
          this.checkSms();
        }, 50);
      });
    }
  }

  async getDatatFromApi(){
    if(this._isMounted){
      try{
        const mailResponse = await Setting17Api.GetMail();
        //check response
        if(!CommonFunction.checkResponseStatus(mailResponse)) return;

        if(mailResponse!=undefined && mailResponse?.status==1){
          let data = mailResponse?.response;
          let activated = mailResponse?.activated;
          const count = (data?.mail.length);
          let notSetAdd = false;
          if (count==0) {
            notSetAdd = true;
          }
          this.setState({
            current_mail: data?.mail,
            mailError: data?.mailError,
            notSetAdd: notSetAdd,
            activated: activated
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        setTimeout(() => {
          this.checkSms();
        }, 50);
        this.setState({loading: false});
      }
    }
  }

  async updateData(values){
    const mail = values['mail'];
    const inputCurrentMail = values['inputCurrentMail'];
    let params;
    if (this.state.notSetAdd==1) {
      params = { mail: mail };
    } else {
      params = { mail: mail, currentMail: inputCurrentMail };
    }
    // メアドと送信用パラメータを保持
    this.setState({
      params: params,
      mailText: mail
    });
    this.popup?.showPopup();
  }

  // SMSアクティベートのチェック
  checkSms() {
    if (this.state.activated==0) {
      this.smsPopup?.showPopup();   
    }
  }

  //  SMSアクティベート:電話番号認証に進む
  onSMSInputAction() {
    // SMS認証の画面に遷移する
    this.navigation.navigate('Auth',{ screen:'SmsInput'});
    setTimeout(() => {
      this.onCloseSmsPopup();
    }, 200);
  }

  //  SMSアクティベート：閉じる
  onCloseSmsPopupAction() {
    this.onCloseSmsPopup();
    // 前の画面に戻る
    this.navigation.goBack();
  }

  //  SMSアクティベート：ポップアップを閉じる
  onCloseSmsPopup() {
    this.smsPopup?.hidePopup();
  }

  // 本人確認；SMS送信のポップアップを閉じる
  onClosePopup() {
    this.popup?.hidePopup();
  }

  // 本人確認；SMS送信アクション
  async onSMSverifyAction() {
    this.popup?.hidePopup();

    try{
      CommonFunction.overLay();
      let params = this.state.params;
      let mail = this.state.mailText;
      const checkMailResponse = await Setting17Api.PostSendSmsSetMail(params);

      // エラー
      const response = checkMailResponse?.response;
      const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
      if(errorCode==ErrorCode.MailInput.registMailMismatch){
        PopupWrapper.showMessage('登録中のメールアドレスが一致しません。入力内容に誤りがないかご確認ください。','warning');
        return;
      }else{
        if(!CommonFunction.checkResponseStatus(checkMailResponse)) return;
      }
      if(checkMailResponse!=undefined && checkMailResponse?.status==1){
        const token = checkMailResponse?.response?.token
        this.navigation.navigate('MailSMSValidate', { token: token, mail: mail});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay(0);
    }
  }

  render() {
    if (this.state.loading) return <ActivityIndicator />;
    let checkValues = {mail:this.state.mail,inputCurrentMail:this.state.inputCurrentMail};
    let checkValidat = Validation.SettingEmailSchema;
    if (this.state.notSetAdd==1) {
      checkValues = {mail:this.state.mail};
      checkValidat = Validation.SettingEmailNotmailSchema;
    }
    return (
      <View style={ListStyles.container}>
      <ScrollView style={styles.ScrollContainer}>
        <Formik
          initialValues={checkValues}
          validationSchema={checkValidat}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.updateData(values) }
        >
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, dirty, handleSubmit, mail }) => (
            <Fragment>
              {this.state.mailError==1 && (
                <View style={{width:'100%'}}>
                  <View style={{paddingTop:5,paddingLeft:10,paddingRight:10,paddingBottom:5}}>
                    <View style={{padding:5, backgroundColor:'#f8e5d8', borderRadius:10}}>
                      <Text style={myStyles.mailErorrHeader}>登録のメールアドレスへの通知を送信エラーなどの理由
                      により停止しています。メールアドレスの変更を頂くか
                      登録済メールアドレスを入力してください。</Text>
                    </View>
                  </View>
                </View>
              )}
              {this.state.notSetAdd!=1 && (
                <View style={{width:'100%'}}>
                  <Text style={myStyles.sectionHeader}>登録中のメールアドレス</Text>
                  <Text style={[styles.labelText, {marginTop:5, paddingLeft:15, fontWeight:'normal'}]}>ご登録のメールアドレスを入力してください</Text>
                  <View style={[styles.labelText, {marginTop:5, paddingLeft:15, paddingRight:30}]}><Text>ヒント：<Text style={{color:'#003fff'}}>{this.state.current_mail}</Text></Text></View>
                  <View style={[styles.BodyContent, {paddingLeft:30, paddingRight:30, paddingBottom:30}]}>
                    <View style={[styles.labelView, {marginTop:0, marginBottom:15,}]}>
                      {errors.inputCurrentMail ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>※{errors.inputCurrentMail}</Text>) : null}
                    </View>
                    <FormTextInputBorder fieldName="inputCurrentMail" type="email" placeholder="メールアドレス" showerror={false}/>
                  </View>
                </View>
              )}
              <View style={{width:'100%'}}>
                <Text style={myStyles.sectionHeader}>メールアドレス変更</Text>
                <View style={[styles.BodyContent, {paddingLeft:30, paddingRight:30}]}>
                  <View style={[styles.labelView, {marginTop:0, marginBottom:15,}]}>
                    <Text style={[styles.labelText, {fontWeight:'normal'}]}>メールアドレスを入力して{"\n"}
                    送信ボタンを押してください{"\n"}
                    メールアドレス変更用の認証コードを送信します
                    </Text>
                  </View>
                  <View style={[styles.labelView, {marginTop:0, marginBottom:15,}]}>
                      {errors.mail ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>※{errors.mail}</Text>) : null}
                  </View>
                  <FormTextInputBorder fieldName="mail" type="email" placeholder="メールアドレス" showerror={false}/>
                  <View style={[styles.buttonPanelSetting, {marginTop:30, marginBottom:25}]}>
                    <TouchableOpacity 
                        style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'60%'}]} 
                        onPress={handleSubmit} 
                        disabled={!(dirty)}
                      >
                      <Text style={styles.loginText}>送信</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={{width:'100%', marginBottom:40}}>
                <Text style={myStyles.sectionHeader}>メールアドレスを変更できない場合</Text>
                <View style={[styles.BodyContent, {paddingTop:25, paddingLeft:30, paddingBottom:25, paddingRight:30}]}>
                  <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#333'}}>迷惑メールフォルダに振り分けされることがございます。ご確認ください。{"\n"}{"\n"}
                  ドメイン指定受信・URL付きメールの受信拒否を設定されている場合、<Text style={{color:'#003fff'}}>[pappy.jp]</Text>をコピーし受信許可をしてください。</Text>
                </View>
              </View>
            </Fragment>
          )}          
        </Formik>
        </ScrollView>
        <Popup ref={ref=>this.smsPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.onCloseSmsPopupAction();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
            <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>メールアドレスの設定、{"\n"}更新には電話番号認証が{"\n"}必要です。</Text>
            <TouchableOpacity onPress={()=>{this.onSMSInputAction()}} style={[myStyles.submitBtn, {marginTop:20}]}>
              <Text style={myStyles.txtBtnWh}>電話番号認証に進む</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{this.onCloseSmsPopupAction()}} style={[myStyles.modalButtonWhite, {marginTop:20}]}>
              <Text style={myStyles.textModalButtonWhite}>閉じる</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        <Popup ref={ref=>this.popup=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                this.onClosePopup();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
              <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>メールアドレスの登録/変更{"\n"}には電話番号での{"\n"}本人確認が必要です。</Text>
              <Text style={{fontSize:17, fontWeight:'normal', color:'#484848', lineHeight:30}}>{"\n"}送信ボタンを押して{"\n"}本人確認をお願いします。{"\n"}{"\n"}</Text>
              <TouchableOpacity onPress={()=>{this.onSMSverifyAction()}} style={[myStyles.submitBtn, {marginTop:20}]}>
                <Text style={myStyles.txtBtnWh}>送信</Text>
              </TouchableOpacity>
            </View>
          </Popup>
      </View>
    );
  }
}

const myStyles = StyleSheet.create({
  sectionHeader:{
    paddingLeft:16,
    width:'100%',
    backgroundColor:'#eef0f1',
    borderTopWidth:1,
    borderTopColor:'#e6e6e6',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
    fontSize:14,
    fontWeight:'normal',
    lineHeight:32,
    color:'#333',        
  },
  mailErorrHeader:{
    width:'100%',
    paddingTop:10,
    paddingLeft:10,
    paddingBottom:10,
    borderTopWidth:1,
    borderTopColor:'#f8e5d8',
    borderBottomWidth:1,
    borderBottomColor:'#f8e5d8',
    fontSize:16,
    fontWeight:'normal',
    lineHeight:25,
    color:'#333',
    textAlign:'left',
  },
  submitBtn:{
    width:"80%",
    margin: 0,
    backgroundColor:"#5cc8ca",
    borderRadius:25,
    height:46,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnWh:{
    color:"white",
    fontWeight:"bold",
    fontSize: 16,
    textAlign:'center'
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
});