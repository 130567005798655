import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform } from 'react-native';
import LoginApi from '../services/api/Login02Api';
import { FormTextInputWithStyle } from '../components/form/FormInput';
import { Formik } from 'formik'
import Validation from '../constants/Validation';
import styles from '../styles/FormStyles';
import HeaderOptions from '../constants/HeaderOptions';
import GoBackIcon from '../components/common/GoBackIcon';
import CommonFunction from '../utils/CommonFunction';
import StorageHelper from '../utils/StorageHelper2';
import ErrorCode from '../constants/ErrorCode';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';

// デバイス変更したログイン時の認証コード入力画面
export default class DeviceVerificationScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      sendAccept: this.props?.route?.params?.sendAccept,
      sendAdd: this.props?.route?.params?.sendAdd,
      sendPass: this.props?.route?.params?.sendPass,
      token: this.props?.route?.params?.token,
      code: ''
    };
    this.keynameMail = 'LoginInfoMail';
    this.keynamePass = 'LoginInfoPass';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  setHeader(){
    this.navigation?.setOptions({
      headerStyle: HeaderOptions.HeaderNoBorder2,
      headerTitle: '',
      headerLeft: () => <GoBackIcon navigation={this.navigation} />
    });
  }

  onSubmit = async (values) => {
    const mail = this.state.sendAdd;
    const pass = this.state.sendPass;
    const accept = this.state.sendAccept;
    const token = this.state.token;
    const params = {mail: mail, pass: pass, token: token, code: values.code};
    try{
      CommonFunction.overLay();
      // 本人確認チェック有りのログイン処理
      const checkLoginPutAuth = await LoginApi.LoginPutCode(params);
      const response = checkLoginPutAuth?.response;
      const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
      if(errorCode==ErrorCode.DeviceVerifi.identityVerifi){
        return;
      }

      // login complete
      if(checkLoginPutAuth?.status==1){
        CommonFunction.setAccessTime();
        ProfileSettingApi.setCheckAccessFlg(false);
        await ProfileSettingApi.ProfileGetCheck(this.context);
      
        // ログイン情報
        if(accept==true){
          // ログイン情報を保持する
          StorageHelper.storeData(this.keynameMail, mail);
          StorageHelper.storeData(this.keynamePass, pass);
        }else{
          // ログイン情報を保持しない
          StorageHelper.removeItem(this.keynameMail);
          StorageHelper.removeItem(this.keynamePass);
        }

        // remove temp register data if exists
        const removeKeys = ['registProfile'];
        StorageHelper.removeItems(removeKeys);

        if(Platform.OS !== 'web'){
          StorageHelper.removeItem('deviceToken');
        } else{
          StorageHelper.removeItem('deviceToken_web');
        }

        // goto search screen
        this.navigation?.navigate('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    return (
      <View style={styles.container}>
      <ScrollView style={styles.ScrollContainer}>
        <View style={[styles.HeadFormSection, {paddingTop:60, paddingLeft:30, paddingRight:30,}]}>
          <Text style={styles.txtHeadForm}>届いたコードを</Text>
          <Text style={styles.txtHeadForm}>入力してください</Text>
        </View>
        <Formik
          initialValues={{code:this.state.code}}
          validationSchema={Validation.DeviceVerifyCode}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.onSubmit(values) }
        >
        {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
          <Fragment>
            <View style={[styles.BodyContent, {padding:30,}]}>
              <View style={{marginTop:0,}}>
                <Text style={[styles.labelText,{color:'#2e8bff',paddingTop:3}]}>{this.state.sendAdd}</Text>
                <Text style={[styles.labelText, {paddingTop:3}]}>に届いたコード</Text>
                <FormTextInputWithStyle fieldName="code" type="text" placeholder="届いたコードを入力してください"/>
              </View>
              <View style={[styles.buttonPanelSetting, {marginTop:35,}]}>
                <TouchableOpacity style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} disabled={!(dirty)} onPress={handleSubmit}>
                  <Text style={styles.loginText}>送信</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Fragment>
        )}          
        </Formik>
        </ScrollView>
      </View>
    );
  }
}
