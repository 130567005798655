import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { Formik } from 'formik'
import Validation from '../../constants/Validation';
import styles from '../../styles/FormStyles';
import { FormTextInputBorder } from '../../components/form/FormInput';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import Setting17Api from '../../services/api/Setting17Api';
import Msg from '../../constants/option/Msg.json';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ListStyles from '../../styles/ListStyles';
import CommonFunction from '../../utils/CommonFunction';

export default class PassSMSValidateScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code : '',
      loading: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      this.props?.setHeader('パスワードの変更',this.navigation);
      this.setState({loading: false});
    }
  }

  async updateData(values){
    try{
      CommonFunction.overLay();
      const token = this.props?.route?.params?.token;
      const newPass = this.props?.route?.params?.newPass;
      const verifyCodeResponse = await Setting17Api.PutPassword(
        token,
        newPass,
        values['code'],
      );
      // エラー
      if(!CommonFunction.checkResponseStatus(verifyCodeResponse)) return;

      if(verifyCodeResponse!=undefined && verifyCodeResponse?.status==1){
        this.popup?.showPopup();
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  gotoSetting(){
    this.navigation?.navigate('Setting');
  }

  onClosePopup = ()=>{
    this.popup?.hidePopup();
    setTimeout(() => {
      this.gotoSetting();
    }, 300);
  }

  render() {
    if (this.state.loading) return <ActivityIndicator />

    return (
      <View style={ListStyles.container}>
        <ScrollView style={styles.ScrollContainer}>
        <Formik
          initialValues={{code:this.state.code}}
          validationSchema={Validation.EmailVerifyCode}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.updateData(values) }
        >
          {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
              <View style={{width:'100%'}}>
                <View style={[styles.BodyContent, {padding: 30}]}>
                  <View style={[styles.labelView, {marginTop:0, marginBottom:40}]}>
                    <Text style={[styles.labelText, {fontWeight:'normal'}]}>ご本人確認のためSMSによる認証をお願い致します。{"\n"}
                    登録されている電話番号に認証コードを送信しました。{"\n"}
                    認証コードを入力してください。
                    </Text>
                  </View>
                  <View style={[styles.labelView, {marginTop:0, marginBottom:15}]}>
                    {errors.code ? 
                    (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>※{errors.code}</Text>) 
                    : null
                    }
                  </View>
                  <View style={[styles.labelView, {marginTop:0, marginBottom:10}]}>
                    <Text style={styles.labelText}>コードを入力してください 
                    </Text>
                  </View>
                  <FormTextInputBorder fieldName="code" type="text" placeholder="" showerror={false}/>
                  <View style={[styles.buttonPanelSetting, {marginTop:30, marginBottom:20}]}>
                    <TouchableOpacity 
                        style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} 
                        onPress={handleSubmit} 
                        disabled={!(dirty)}
                      >
                      <Text style={styles.loginText}>認証する</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Fragment>
          )}          
          </Formik>
          <Popup ref={ref=>this.popup=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                this.onClosePopup();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'80%', backgroundColor:'#fefefe', borderRadius: 12}}>
              <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24}}>{Msg.PasswordChange.Success}</Text>
              <TouchableOpacity onPress={()=>{this.onClosePopup()}} style={[styles.btnGray, {marginTop:20}]}>
                <Text style={styles.txtBtnGray}>OK</Text>
              </TouchableOpacity>
            </View>
          </Popup>
        </ScrollView>
      </View>
    );
  }
}