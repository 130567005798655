import { Platform } from 'react-native';
import axios from 'axios'
import StorageHelper from '../utils/StorageHelper2';
import CryptoJS from 'crypto-js';
import CommonFunction from './CommonFunction';

export default class ApiPappy {
  constructor() {
    this.AuthHeader = {};
    this.result = {};
    this.checkAccessFlg = true;
  }

  static async setAuthToken(){
    let accessToken = await StorageHelper.getData('accessToken');
    if(!accessToken) accessToken = '';
    const appid = this.getAppId();
    this.AuthHeader = {
      "X-App-Id" : appid,
      "X-Access-Token" : accessToken
    };
  }

  static async setNonAuthToken(){
    const appid = this.getAppId();
    this.AuthHeader = {
      "X-App-Id" : appid
    };
  }

  static async setContentType(){
    this.AuthHeader = {
      ...this.AuthHeader,
      "Content-Type": "multipart/form-data"
    };
  }

  static getAppId(){
    let appid = '';
    switch(Platform.OS){
      case 'web':
        appid = 'pappyAppWeb';
        break;
      case 'ios':
        appid = 'pappyAppIos';
        break;
      case 'android':
        appid = 'pappyAppAndroid';
        break;
    }
    return appid;
  }

  static objToQueryString(obj){
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }

    return keyValuePairs.join('&');
  }

  static setCheckAccessFlg(val){
    this.checkAccessFlg = val;
  }

  static resetCheckAccessFlg(){
    this.checkAccessFlg = true;
  }

  static getCheckAccessFlg(){
    if(this.checkAccessFlg===false){
      return false;
    }
    return true;
  }

  static async get(url, data, auth=false){
    const queryString = this.objToQueryString(data);
    if(auth){
      await this.setAuthToken();
    }else{
      await this.setNonAuthToken();
    }

    this.result = await axios.get(url+`?${queryString}`, { headers:this.AuthHeader }).then(response => {
      if(response.status==200){
        if(Platform.OS!=='web'){
          if(response.data.version!=undefined){
            // アプリの時のみチェック対象
            let minVer = response.data.version.min;
            let maxVer = response.data.version.max;
            if (CommonFunction.checkAppVersion(minVer, maxVer) == false) {
              return {};
            }
          }
        }
        if(response.data.accessToken!==undefined){
          StorageHelper.storeData('accessToken', response.data.accessToken);
        }
      }else{
        //error
      }
      return response;
    })
    .catch(async err =>  {
      console.error(err);      
    });

    return this.result;
  }

  static async post(url, data, auth=false, encode=true, exclude=[]){
    if(auth){
      const checkAccessFlg = this.getCheckAccessFlg();
      if(checkAccessFlg!==false){
        const returnAccessFlg = await CommonFunction.checkAccessTime();
        if(returnAccessFlg===false){
          return {};
        }
      }
      await this.setAuthToken();
    }else{
      await this.setNonAuthToken();
    }
    
    let bodyFormData;
    if(data && JSON.stringify(data)!='{}'){
      await this.setContentType();
      bodyFormData = new FormData();
      if(encode===false){ // not encode data
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            bodyFormData.append(key, data[key]);
          }
        }
      }else{ // encode data
        let b = {};
        if(Array.isArray(exclude) && exclude.length > 0){
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if(exclude?.indexOf(key)!=-1){
                bodyFormData.append(key, data[key]);
              }else{
                b[key] = data[key];
              }
            }
          }
        }else{
          b = data;
        }

        let stringData = JSON.stringify(b);
        if(stringData!=='{}'){
          let encodeData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(stringData));
          // for decode use: CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encodeData));
          bodyFormData.append('b', encodeData);
        }
      }
    }else{
      bodyFormData = {};
    }

    this.result = await axios.post(url, bodyFormData, { headers: this.AuthHeader }).then(response => {
      if(response.status==200){
        if(Platform.OS!=='web'){
          // アプリの時のみチェック対象
          if(response.data.version!=undefined){
            let minVer = response.data.version.min;
            let maxVer = response.data.version.max;
            if (CommonFunction.checkAppVersion(minVer, maxVer) == false) {
              return {};
            }
          }
        }
        if(response.data.accessToken!==undefined){
          StorageHelper.storeData('accessToken', response.data.accessToken);
          StorageHelper.storeData('DeviceVerification', response.data.accessToken);
        }
      }else{
        // error
      }
      return response;
    })
    .catch( err => {
      console.error(err);      
    });

    return this.result;
  }

  static makeTabData(obj){
    let array = [];
    if(typeof obj=='object'){
      obj.map((val) => {
        if(val) array.push(val.id)  
      });
    }

    if(array.length>0) 
      return array.join('\t');
    else 
      return ''; 
  }
}
